var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.ts
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Center,
  ChakraProvider,
  Checkbox,
  CheckboxGroup,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  CloseButton,
  ColorModeContext,
  Container,
  DarkMode,
  Divider,
  extendTheme as extendTheme2,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Hide,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Kbd,
  LightMode,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Progress,
  Show,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useClipboard,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { ThemeContext } from "@emotion/react";

// src/components/CustomCard.tsx
import React from "react";
import {
  forwardRef,
  useStyleConfig,
  chakra
} from "@chakra-ui/react";
var CustomCard = forwardRef((props, ref) => {
  const _a = props, { variant } = _a, rest = __objRest(_a, ["variant"]);
  const styles = useStyleConfig("CustomCard", { variant });
  return /* @__PURE__ */ React.createElement(chakra.div, __spreadValues({ ref, __css: styles }, rest));
});
var CustomCardTheme = {
  baseStyle: ({ theme: theme2 }) => ({
    isolation: "isolate",
    position: "relative",
    borderRadius: `clamp(0px, calc((100vw - 4px - 100%) * 9999), ${theme2.radii.md})`
  }),
  variants: {
    default: ({ colorMode, theme: theme2 }) => ({
      bgColor: colorMode === "dark" ? "gray.700" : "white",
      color: colorMode === "dark" ? "white" : "gray.700",
      boxShadow: "md"
    }),
    outline: {
      border: "1px solid",
      borderColor: "gray.200"
    },
    acrylic: ({ colorMode }) => ({
      bgColor: colorMode === "dark" ? "blackAlpha.800" : "whiteAlpha.800",
      color: colorMode === "dark" ? "gray.200" : "gray.800",
      border: "1px solid",
      borderColor: colorMode === "dark" ? "whiteAlpha.400" : "whiteAlpha.700",
      backdropFilter: "auto",
      backdropBlur: "20px",
      backdropSaturate: "500%",
      backdropContrast: "50%",
      boxShadow: "md"
    })
  },
  defaultProps: {
    variant: "default"
  }
};

// src/theme.ts
import {
  extendTheme,
  theme as baseTheme
} from "@chakra-ui/react";
var theme = extendTheme(
  {
    fonts: {
      heading: "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue', helvetica, Ubuntu, roboto, noto, arial, sans-serif",
      body: "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'segoe ui', 'helvetica neue', helvetica, Ubuntu, roboto, noto, arial, sans-serif",
      mono: "SFMono-Regular,Menlo,Monaco,Consolas,'Liberation Mono','Courier New',monospace"
    },
    shadows: {
      outline: "0 0 0 3px var(--chakra-colors-primary-400)"
    },
    components: {
      CustomCard: CustomCardTheme
    }
  },
  baseTheme
);
export {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Center,
  Checkbox,
  CheckboxGroup,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  CloseButton,
  ColorModeContext,
  Container,
  CustomCard,
  DarkMode,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Hide,
  Icon,
  IconButton,
  Image,
  Input,
  Kbd,
  LightMode,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Progress,
  Show,
  SimpleGrid,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Spinner,
  Stack,
  Switch,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  ThemeContext,
  ChakraProvider as ThemeProvider,
  Tooltip,
  Tr,
  VStack,
  extendTheme2 as extendTheme,
  theme,
  useBreakpointValue,
  useClipboard,
  useColorMode,
  useColorModeValue,
  useDisclosure,
  useToast
};
