import "~/lib/createimagebitmap-polyfill";
import "styles/index.css";

import { ReactElement } from "react";
import type { AppProps } from "next/app";
import Router from "next/router";
import { ThemeProvider, theme } from "@treasured/sdk";

import { AnalyticsProvider, Analytics } from "~/utils/analytics";
import { useLogRocket } from "~/hooks/useLogRocket";

// Polyfill to fix iOS issue of Object.fromEntries not being available
const customFromEntries = (arr: any[]) =>
  arr.reduce((acc, [k, v]) => ((acc[k] = v), acc), {});
Object.fromEntries = Object.fromEntries || customFromEntries;

// Track initial pageview on client-side
if (typeof window !== "undefined") {
  Analytics.page();
}

// Track pageview on route change
Router.events.on("routeChangeComplete", () => {
  Analytics.page();
});

function LogRocketComponent() {
  useLogRocket({});

  return null;
}

export default function App({ Component, pageProps }: AppProps): ReactElement {
  return (
    <>
      <LogRocketComponent />
      <ThemeProvider theme={theme}>
        <AnalyticsProvider>
          <Component {...pageProps} />
        </AnalyticsProvider>
      </ThemeProvider>
    </>
  );
}
